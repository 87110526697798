/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ol,
ul {
  list-style: none;
  padding-inline-start: 0px;
}
a {
  color: inherit;
}
/***************** Variables **********************/
:root {
  --primary: #ffffff;
  --primaryHover: rgb(187, 170, 204, 0.25);
  --dark-green: #325f80;
  --input-border: #d8d8d8;
  --white: #ffffff;
  --light-blue: #8fbfe2;
  --dark-blue: #1e3c50;
  --orange: #df6535;
  --background-grey: #f7f7fb;
  --title-color: #595959;
  --title-light: #707070;
  --water: #367397;
  --red: #b71c1c;
  --error: rgb(226, 171, 171);
  --input-title: #0000008a;
  --status-grey: #545454;
  --light-purple: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.MuiInput-underline::after {
  border-bottom: 0px !important;
}
.MuiInput-underline::before {
  border-bottom: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Change the white to any color ;) / */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f7f7fb inset !important;
}

.col-md-6 {
  width: 85%;
}
.col-md-6 > img {
  width: 100%;
}
.item__photo {
  width: 45%;
}
.item__photo > img {
  width: 100%;
}
