.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Toastify__progress-bar {
  /* display: none; */
}
.Toastify__progress-bar--animated {
  display: none;
}

.Toastify__toast--success {
  background: #43a047 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}

.Toastify__toast--info {
  background: #1976d2 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}
.Toastify__toast--warning {
  background: #ffa000 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}
.Toastify__toast--error {
  background: #d32f2f 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}

.MuiList-padding {
  /* padding-top: 0 !important; */
  /* padding-bottom: 0 !important; */
}

a {
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media print {
  .pageBreak div {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

.MuiTableCell-body{
  vertical-align: top!important;
}

.MuiCheckbox-root{
  padding: 0 8px!important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.MuiTableCell-head .MuiFormControlLabel-root{
  height: 18px!important;
}