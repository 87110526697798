@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ol,
ul {
  list-style: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}
a {
  color: inherit;
}
/***************** Variables **********************/
:root {
  --primary: #ffffff;
  --primaryHover: rgb(187, 170, 204, 0.25);
  --dark-green: #325f80;
  --input-border: #d8d8d8;
  --white: #ffffff;
  --light-blue: #8fbfe2;
  --dark-blue: #1e3c50;
  --orange: #df6535;
  --background-grey: #f7f7fb;
  --title-color: #595959;
  --title-light: #707070;
  --water: #367397;
  --red: #b71c1c;
  --error: rgb(226, 171, 171);
  --input-title: #0000008a;
  --status-grey: #545454;
  --light-purple: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.MuiInput-underline::after {
  border-bottom: 0px !important;
}
.MuiInput-underline::before {
  border-bottom: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Change the white to any color ;) / */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f7f7fb inset !important;
}

.col-md-6 {
  width: 85%;
}
.col-md-6 > img {
  width: 100%;
}
.item__photo {
  width: 45%;
}
.item__photo > img {
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Toastify__progress-bar {
  /* display: none; */
}
.Toastify__progress-bar--animated {
  display: none;
}

.Toastify__toast--success {
  background: #43a047 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}

.Toastify__toast--info {
  background: #1976d2 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}
.Toastify__toast--warning {
  background: #ffa000 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}
.Toastify__toast--error {
  background: #d32f2f 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}

.MuiList-padding {
  /* padding-top: 0 !important; */
  /* padding-bottom: 0 !important; */
}

a {
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media print {
  .pageBreak div {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

.MuiTableCell-body{
  vertical-align: top!important;
}

.MuiCheckbox-root{
  padding: 0 8px!important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.MuiTableCell-head .MuiFormControlLabel-root{
  height: 18px!important;
}
